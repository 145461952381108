
import React, { useState, useEffect } from 'react';


function Header(props) {
    let add =props.isFixed === true ? " nav-fixed" : "";
    let clazz = "fixed-top" + add;

    let logoSrc = props.isFixed === true ? "assets/images/logo-black.png" : "assets/images/logo.png";

    return (
        <header id="site-header" className={clazz}>
            <div className="nav-info-bar" style={{textAlign:"center"}}>
                <a href="tel:+19144541950" class="nav-info-bar-item">
                    <span class="fas fa-phone" style={{marginRight: "4px"}}></span><span>(914) 454-1950</span>
                </a>
                <span className="nav-info-bar-splitter">|</span>
                <a className="rm-pointer">
                    <span class="fas fa-globe" style={{marginRight: "4px"}}></span><span>Hawthorne, NY</span>
                </a>
            </div>

            <div className="container mt-lg-1">
                <nav className="navbar navbar-expand-lg stroke px-0 pt-lg-0">
                    <a className="navbar-brand" href="/">
                        <img alt="Main logo" id="main-logo" src={logoSrc} width="250px" />
                    </a>
                    <button className="navbar-toggler  collapsed bg-gradient" type="button" data-toggle="collapse"
                        data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon fa icon-expand fa-bars"></span>
                        <span className="navbar-toggler-icon fa icon-close fa-times"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                        <ul className="navbar-nav mr-lg-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/team">Team</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/services">Services</a>
                            </li>

                         
                            
                            
            
                            <li className="nav-item">
                                <a className="nav-link" href="/contact">Contact</a>
                            </li>
                        </ul>

                        

                        <div class="top-quote mr-lg-3 mt-lg-0 mr-lg-4">
                            <a href="/contact" class="btn btn-style btn-white btn-primary" style={{padding: "10px 28px", fontSize: "14px"}}>
                                Get a Free Consultation
                            </a>
                        </div>
                    </div>
                    

                </nav>
            </div>
        </header>
    );
}

export default Header;