import Header from '../Components/Header';
import Footer from '../Components/Footer';
import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";

function Contact() {

    useEffect(() => {
        const $ = window.$;

        $(window).on("scroll", function () {
            var scroll = $(window).scrollTop();

            if (scroll >= 80) {
                $("#site-header").addClass("nav-fixed");
                $("#main-logo").attr("src", "assets/images/logo-black.png");
            } else {
                $("#site-header").removeClass("nav-fixed");
                $("#main-logo").attr("src", "assets/images/logo.png");
            }
        });
    })


    return (
        <div>
            <Helmet>
                <title>Contact Us | Solace Integrations | Hawthorne, NY</title>
                <link rel="canonical" href="https://solaceintegrations.com/contact" />
            </Helmet>
            <Header />

            <div class="inner-banner">
            </div>
            <section class="w3l-breadcrumb" style={{textAlign: "left"}}>
                <div class="container">
                    <ul class="breadcrumbs-custom-path">
                        <li><a href="/">Home</a></li>
                        <li class="active"><span class="fa fa-arrow-right mx-2" aria-hidden="true"></span> Contact Us</li>
                    </ul>
                </div>
            </section>

            <section class="w3l-contact-1 py-5" id="contact">
                <div class="contacts-9 py-lg-5 py-md-4">
                    <div class="container">
                        <div class="contactct-fm map-content-9">
                            <div class="header-title text-center">
                                <h6 class="title-subhny"><span>contact Us</span></h6>
                                <h3 class="title-w3l mb-2">Let’s Talk About
                                    <span class="span-bold"> Your Project</span></h3>
                                <p class="mb-sm-5 mb-4"></p>
                            </div>
                            <form action="https://getform.io/f/bwngrjva" class="pt-lg-4" method="post">
                                <div class="twice-two">
                                    <input type="text" class="form-control" name="w3lName" id="w3lName" placeholder="Name" required="" />
                                    <input type="email" class="form-control" name="w3lSender" id="w3lSender" placeholder="Email" required="" />
                                    <input type="text" class="form-control" name="w3lSubject" id="w3lSubject" placeholder="Subject"
                                        required="" />
                                </div>
                                <textarea name="w3lMessage" class="form-control" id="w3lMessage" placeholder="Message"
                                    required=""></textarea>
                                <div class="text-lg-center">
                                    <button type="submit" class="btn btn-primary btn-style mt-lg-5 mt-4">Send Message</button>
                                </div>
                            </form>
                        </div>
                        <div class="row contact-view mt-5 pt-lg-5">
                            <div class="col-lg-4 col-md-6 cont-details">
                                <div class="contactct-fm-text text-left">
                                    <h6 class="title-subhny"><span>Contact Us</span></h6>
                                    <h3 class="title-w3l mb-5">Direct Support</h3>

                                </div>
                            </div>



                        </div>

                        <div class="row" style={{textAlign: "left"}}>
                            <div class="col-lg-4 col-md-6 mt-xs-3 mt-sm-3 cont-details">
                                <div class="cont-top">
                                    <div class="cont-left text-center">
                                        <span class="fas fa-phone-alt"></span>
                                    </div>
                                    <div class="cont-right">
                                        <h5>Phone</h5>
                                        <p><a href="tel:+19144541950">(914) 454-1950</a></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mt-xs-3 mt-sm-3 cont-details">
                                <div class="cont-top">
                                    <div class="cont-left text-center">
                                        <span class="fas fa-envelope-open-text"></span>

                                    </div>
                                    <div class="cont-right">
                                        <h5>Email</h5>
                                        <p><a href="mailto:info@solaceintegrations.com" class="mail">info@solaceintegrations.com</a></p>
                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-4 col-md-6 mt-xs-3 mt-sm-3 cont-details">
                                <div class="cont-top">
                                    <div class="cont-left text-center">
                                        <span class="fas fa-map-marker-alt"></span>
                                    </div>
                                    <div class="cont-right">
                                        <h5>Location</h5>
                                        <p class="pr-lg-5">Hawthorne, NY</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    );
}

export default Contact;